
import './styles.css';

export function Input( props) {
  return (
    <input
    className='form-input'
      {...props}
    />
  );
}